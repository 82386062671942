<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="pageMain">
      <img style="width: 100%" src="@/assets/other-1.png" />
      <div class="content">
        <el-form
          label-width="80px"
          :model="params"
          label-position="top"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="params.name"
              placeholder="请填写您的姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="phone">
            <el-input
              v-model="params.phone"
              placeholder="请填写您的邮箱或电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="使用单位" prop="unitsOfUse">
            <el-input
              v-model="params.unitsOfUse"
              placeholder="请填写使用单位"
            ></el-input>
          </el-form-item>
          <el-form-item label="使用时间" prop="endTime">
            <el-date-picker
              v-model="time1"
              type="date"
              style="width: calc(50% - 10px)"
              placeholder="开始时间"
              value-format="timestamp"
              @change="changeTime($event, 1)"
            >
            </el-date-picker>
            <span class="inbl-m t-c" style="width: 20px;">-</span>
            <el-date-picker
              v-model="time2"
              type="date"
              style="width: calc(50% - 10px)"
              placeholder="结束时间"
              value-format="timestamp"
              @change="changeTime($event, 2)"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="作曲家" prop="composer">
            <el-select
              v-model="params.composer"
              placeholder="请选择作曲家"
              style="width: 100%"
            >
              <el-option
                v-for="item in composerOptions"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用目的" prop="objective">
            <div
              class="mb-10"
              v-for="(item, index) in objectiveList"
              :key="index"
            >
              <span
                class="text-1 inbl-m"
                :class="currentObjective.value == item.value ? 'text-2' : ''"
                @click="changeObjective(item)"
                >{{ item.text }}</span
              >
              <el-input
                v-model="otherObjective"
                placeholder="请填写其他使用目的"
                v-if="item.value == 2"
                class="inbl-m ml-10"
                style="width: calc(100% - 90px)"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div class="btn-1 mt-68 cur" @click="submit">提交申请</div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<style scoped>
</style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      isIndex: false,
      objectiveList: [
        { text: "用于演出", value: 1 },
        { text: "其他", value: 2 },
      ],
      currentObjective: {},
      composerOptions: [],
      params: {
        name: "",
        phone: "",
        unitsOfUse: "",
        startTime: "",
        endTime: "",
        composer: "",
        objective: "",
      },
      otherObjective: "",
      time1: "",
      time2: "",
      rules: {
        name: [{ required: true, message: "请填写您的姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请填写您的邮箱或电话", trigger: "blur" },
        ],
        unitsOfUse: [
          { required: true, message: "请填写使用单位", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择使用时间", trigger: "change" },
        ],
        composer: [
          { required: true, message: "请选择作曲家", trigger: "change" },
        ],
        objective: [
          { required: true, message: "请选择使用目的", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    // 获取作曲家
    this.getComposer();
    this.changeObjective(this.objectiveList[0]);
  },
  methods: {
    // 使用目的
    changeObjective(item) {
      this.currentObjective = item;
      this.params.objective = this.currentObjective.text;
    },
    // 获取作曲家
    getComposer() {
      let queryParams = {
        artistTypeId: 5,
      };
      this.$ajax
        .get("https://api.sucoch.com/vod/public/getArtist", queryParams)
        .then((res) => {
          if (res.code == "200") {
            this.composerOptions = res.data || [];
          }
        });
    },
    // 时间
    changeTime(e, type) {
      if (type == 1) {
        this.params.startTime = e / 1000;
      } else {
        this.params.endTime = e / 1000;
      }
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.currentObjective.value == 2) {
            if (!this.otherObjective) {
              this.$message.warning("请填写其他使用目的");
              return;
            } else {
              this.params.objective = this.otherObjective;
            }
          }
          this.$ajax
            .post(
              "https://api.sucoch.com/vod/public/rentMusicScore",
              this.params,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              if (res.code == "200") {
                this.$message.success("提交成功");
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.content {
  .el-form--label-top .el-form-item__label {
    padding-bottom: 8px;
    line-height: 18px;
  }
}
</style>
<style lang="scss" scoped>
.content {
  background: #fff;
  margin-top: -3px;
  padding: 18px 14px 35px;

  .text-1 {
    display: inline-block;
    width: 79px;
    height: 33px;
    border-radius: 90px;
    background: #d9d9d9;
    color: #484341;
    text-align: center;
    line-height: 33px;
    font-size: 14px;
    cursor: pointer;
  }

  .text-2 {
    background: #c28e52;
    color: #ffffff;
  }

  .btn-1 {
    margin: 0 auto;
    width: 100%;
    height: 40px;
    background: #c28e52;
    border-radius: 8px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
  }
}
</style>

